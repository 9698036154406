import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { bt_darkblue } from "../components/color"
import Layout, { Section } from "../components/layout"
import SEO from "../components/seo"

const StyledPolicyDocumentContainer = styled(Section)`
  color: ${bt_darkblue};
  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.33rem;
  }
  h2 {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
  ul {
    // list-style-position: inside;
    // padding-inline-start: 0;
    li {
      margin: 1rem 0px;
    }
  }
`

export default function Policy() {
  const data = useStaticQuery(graphql`
    query {
      uploadDataImg1: file(relativePath: { eq: "upload_data_step_1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uploadDataImg2: file(relativePath: { eq: "upload_data_step_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout IsDarkTheme>
      <SEO
        title="Policy"
        description="An overview of BlueTrace and the TraceTogether app for leaders, policymakers and development teams"
      />
      <StyledPolicyDocumentContainer>
        <div className="container">
          <h1>TraceTogether - an overview</h1>
          <p>
            Since the launch of TraceTogether on 20 Mar 2020, we have received
            many suggestions and requests for the app to be shared with other
            countries and organisations. We were unprepared for the overwhelming
            level of interest. We apologise for not being more responsive to
            those who have reached out, but rest assured that we are trying our
            best.
          </p>
          <p>
            The COVID-19 pandemic is a global problem that knows no boundaries.
            We recognise that a Bluetooth contact tracing app like TraceTogether
            can be helpful in other countries. However, the biggest and most
            important caveat is that we are still in the very early stages of
            using TraceTogether to help with Singapore’s contact tracing
            operations, and{" "}
            <strong>
              <u>
                it is too early to tell how effective TraceTogether actually is
              </u>
            </strong>
            . Nonetheless, we have decided to open source the app and the
            underlying BlueTrace protocol in the hope that it could be useful,
            so that the global software development community can collectively
            improve it, and so that governments and public health authorities
            may adapt it.
          </p>
          <p>
            Besides the actual source code and technical documentation, we think
            it is useful to explain in greater detail how the app works, the
            situations in which it can potentially improve contact tracing, and
            some of the limitations which you should be aware of.
          </p>
          <p>
            This document is written primarily for{" "}
            <strong>government leaders and policymakers</strong>, but it should
            be helpful to app development teams as well. In particular, it is
            meant to help leaders decide whether a contact tracing app like
            TraceTogether would be useful in your operating context, as well as
            the potential modifications to Singapore’s implementation that be
            needed.
          </p>
          <h2>
            <em>Contact Tracing in Singapore</em>
          </h2>
          <p>
            TraceTogether was designed from the ground-up to support Singapore’s
            contact tracing process, with the close involvement of the national
            public health authority from the very beginning. Its design is
            informed by two considerations: a){" "}
            <strong>centralised contact tracing operations</strong>; and b){" "}
            <strong>decentralised activity recall</strong>.
          </p>
          <ul>
            <li>
              <u>Centralised contact tracing operations</u>. When a person is
              diagnosed with COVID-19, he/she will be contacted by the
              Government’s contact tracing team under the charge of the Ministry
              of Health. All contact tracing operations are centralised in this
              team.
            </li>
            <li>
              <u>Decentralised recall</u>. The contact tracer will ask the
              person to recall all the places he/she has been to in the last 14
              days, as well as the persons they have been contact with. This
              activity mapping process is tedious but necessary, in order to
              identify all the close contacts whom they may have exposed the
              SARS-CoV-2 virus to, as well as identify close contacts they may
              have caught the virus from, so as to establish links between
              clusters of COVID-19 cases. To aid the recall, individuals usually
              refer to their schedules, social media history, etc. However, what
              is usually impossible to know, is close contact with unacquainted
              contacts, such as at restaurants, large social/work events, or
              even on public transport.{" "}
              <strong>
                TraceTogether and other Bluetooth-based contact/proximity
                tracing solutions attempt to plug this gap, by recording{" "}
                <em>who</em> you have been in contact with, but not{" "}
                <em>where</em>.
              </strong>
            </li>
          </ul>
          <h2>
            <em>How TraceTogether works</em>
          </h2>
          <p>
            In Singapore, downloading the app is voluntary (more on app adoption
            in a later section). The high-level overview of how the app works is
            that you register using a mobile number. Thereafter, the app will
            keep a record of other TraceTogether users you have been in close
            proximity with, and stores all the information on the phone with
            scrambled identifiers to protect the identity of users. Only when
            approached by a contact tracer will a user be requested to upload
            his/her data stored on the phone to a central server owned by the
            Ministry of Health to facilitate contact tracing.
          </p>
          <ul>
            <li>
              <u>Minimal personal data is collected</u>. When the app is
              installed, users will be asked to register using a Singapore
              mobile number.{" "}
              <strong>
                This mobile number is the <u>only</u> personal data that the
                Ministry of Health will collect from the user
              </strong>
              , and it is stored in a secured server together with a random
              anonymised User ID that is linked to one’s mobile number. The
              security level of this server is as high as other Government
              servers that store official information.{" "}
              <strong>
                The mobile number is what allows individuals to be contacted, so
                that he may receive the appropriate guidance and care.
              </strong>
            </li>
            <li>
              <u>No access to location data</u>. TraceTogether does not have
              access to nor does it require location data.
            </li>
            <li>
              <u>A user’s identity is never revealed to other users</u>. The app
              creates a temporary ID, generated by encrypting the User ID so
              that only the Ministry of Health can decrypt it. The temporary ID
              is changed frequently, making it difficult to spoof. When the app
              is running, it regularly scans for nearby TraceTogether users
              using Bluetooth. When it is able to establish a Bluetooth
              connection with such nearby users, using what we call the{" "}
              <strong>
                <Link to="/">BlueTrace protocol</Link>
              </strong>
              , the app will record the temporary ID of the nearby phone,
              together with information about the phone’s model, Bluetooth
              signal strength, and time. The phone therefore never records any
              personal identifiable information. Furthermore, all this
              information is stored on the phone, and
              <strong>
                is never uploaded to the Ministry of Health or shared with
                anyone else
              </strong>
              , unless explicitly authorised and initiated by the user.
            </li>
            <li>
              <u>Records will only be uploaded during contact tracing</u>. In
              the event a TraceTogether user is contacted by the contact tracer,
              he/she will be asked to upload the proximity history stored in the
              TraceTogether app to the Ministry of Health. We have put in place
              an authentication mechanism to ensure that only a genuine contact
              tracer can request a user to upload his/her data (see below
              screenshots).
            </li>
          </ul>
          <div className="row">
            <div className="col-10 offset-1 col-sm-6 offset-sm-0 col-md-4 offset-md-2 col-lg-3 offset-lg-3">
              <Img
                fluid={data.uploadDataImg1.childImageSharp.fluid}
                style={{ marginBottom: "1rem" }}
              />
            </div>
            <div className="col-10 offset-1 col-sm-6 offset-sm-0 col-md-4 col-lg-3">
              <Img
                fluid={data.uploadDataImg2.childImageSharp.fluid}
                style={{ marginBottom: "1rem" }}
              />
            </div>
          </div>
          <ul>
            <li>
              <u>
                TraceTogether records will facilitate, but not replace, contact
                tracing
              </u>
              . When TraceTogether records are received by the contact tracing
              team, they will be able to view the phone numbers of probable
              close contacts. Once the contact tracers have the list of probable
              contacts, they would have to cold call these persons. (Users agree
              during onboarding to be contacted by contact tracers if they have
              been exposed to SARS-CoV-2.) Since the contact tracers only know
              the mobile number, and not the name or how/where the person could
              have come into contact with a COVID-19 case, the communications
              protocol is designed such that the contact tracer must exercise
              judgment to account for systematic biases, e.g. short-duration
              contact in an unventilated space or phones not actively carried by
              owners, in deciding on the necessary follow-up.
            </li>
          </ul>
          <p>
            It is useful at this point to describe in a bit more detail, but
            without being too technical, how information recorded by
            TraceTogether can identify probable close contacts. The two key
            determinants of what constitutes a probable “close contact” are
            distance between contacts, and duration of contact. The definition
            of what constitutes a “close contact” will likely differ from
            country to country, or change as we learn more about the
            epidemiology of SARS-CoV-2. As alluded to above, it is also
            sensitive to context and factors other than just distance and
            duration that are beyond the ability of a Bluetooth contact tracing
            app to account for.
          </p>
          <ul>
            <li>
              <u>Distance between contacts</u>. To estimate the distance between
              two TraceTogether users, we measure the Bluetooth signal strength
              between their devices. Bluetooth transmission power
              characteristics differ from device to device. Hence, to improve
              the accuracy of distance estimation, TraceTogether’s data analysis
              is calibrated using measurements for the most popular mobile
              handsets in the Singapore market. [In Singapore, approximately
              half of smart phones are iPhones, and half are Android, from a
              variety of manufacturers.] For greater accuracy, if a similar app
              is implemented in other countries, such testing should also be
              conducted. We provide a baseline dataset that we invite developers
              and handset manufacturers to contribute to, to serve as a
              universal calibration table for any Bluetooth contact tracing app.
            </li>
            <li>
              <u>Duration of contact</u>. In order to establish duration of
              contact, TraceTogether tries to scan for nearby Bluetooth devices
              in the background, to find other TraceTogether users, at regular
              intervals. However, this does not happen when the user switches
              off Bluetooth, or due to the inherent constraints on the iOS
              version of the app (elaborated later). Assuming the app is able to
              scan at regular intervals, we can then establish a relatively
              accurate estimate of the duration of contact.
            </li>
          </ul>
          <p>
            Besides the client app, there also needs to be a data analysis
            dashboard meant for contact tracers, to make it as easy as possible
            to retrieve each set of uploaded proximity history, and to flag
            probable close contacts for follow-up. This front-end will likely
            need to be integrated into the existing workflows of the contact
            tracers, and will therefore differ from country to country. We have
            found it extremely useful to have a continuing dialogue and
            collaboration between the development team and contact tracing team.
            By articulating the user journeys of the contact tracers and
            healthcare professionals on the frontline, we can continue to
            rapidly prototype and iterate the contract tracing data analysis
            dashboard.
          </p>
          <h2>
            <em>Prerequisites and Limitations</em>
          </h2>
          <p>
            There are two key prerequisites for TraceTogether to be useful to
            contact tracing. First, a large-enough proportion of the population
            must download the app. Second, due to the design of Apple’s iOS,
            iPhone users must actively keep their app running in the foreground.
            These two prerequisites can also be treated as limitations which
            need to be overcome, and the non-technical solutions would likely
            differ from country to country.
          </p>
          <ul>
            <li>
              <u>High app adoption</u>. TraceTogether users can only detect
              other TraceTogether users. We have designed it this way, so that{" "}
              <strong>
                everyone who is being recorded as a contact had{" "}
                <em>consented</em> to doing so
              </strong>
              . This preserves high social trust in the use of the app, as an
              act of social responsibility by individuals. The success of the
              app therefore depends significantly on enough people using the
              app, otherwise the chance of contact between individuals both
              having the app will be low. In Singapore, where the use of the app
              is voluntary, we encouraged app downloads under the ambit of
              SGUnited, the community campaign launched to help Singapore
              overcome COVID-19 as one people.
              <br />
              Apart from general media and social media publicity, the Singapore
              Government also worked with organised groups, e.g. large employers
              and unions, to encourage their members to download and use the
              app. There were many public and private organisations that came on
              board voluntarily, after seeing how useful it will be for their
              staff, and the wellbeing of the general population. After slightly
              more than two weeks after TraceTogether was launched, we crossed a
              million users. While this is promising, this is still less than a
              quarter of the population, and we will continue with the publicity
              and outreach efforts.
            </li>
            <li>
              <u>Active usage of TraceTogether</u>. For Android users,
              TraceTogether is able to regularly scan for nearby Bluetooth
              devices, even when the app is running in the background.{" "}
              <strong>
                However, for iOS users, TraceTogether needs to be kept running
                in the foreground in order to be able to reliably scan for
                nearby devices.
              </strong>{" "}
              This is obviously a major hurdle to usage as users are unlikely to
              keep the app running all, or even most, of the time. We have
              therefore introduced nudges such as occasional push notifications
              to remind iOS users to keep their app running, especially in more
              crowded places like public transport. We have also publicised this
              limitation clearly on our website and in public communications. We
              will continue to explore technical workarounds.
            </li>
            <li>
              <u>Apple and Google app review policies</u>. App developers should
              be aware of Apple’s and Google’s policies on the review of apps
              related to the ongoing COVID-19 pandemic. Apple will only accept
              apps from “recognized entities such as government organisations,
              health-focused NGOs, companies deeply credentialed in health
              issues, and medical or educational institutions”. Google will only
              accept apps “published, commissioned or authorized by official
              government entities and public health organisations”.
            </li>
          </ul>
          <h2>
            <em>Global Interoperability via BlueTrace Protocol</em>
          </h2>
          <p>
            Having each country develop its own version of TraceTogether will
            help with local contact tracing. When international travel resumes,
            and if the spread of COVID-19 has not fully subsided, there may be a
            need for cross-border contact tracing.{" "}
            <strong>
              We have therefore designed the BlueTrace protocol with global
              interoperability in mind.
            </strong>{" "}
            Each country or even state/city-level implementation of BlueTrace
            can specify its unique country and sub-country code. This means that
            BlueTrace-compatible apps (e.g. TraceTogether) will be able to
            record contacts with users of another BlueTrace-compatible app, and
            governments can work together to design a mutual assistance process
            for contact tracing.
          </p>
          <p>
            Achieving this global interoperability will obviously require a lot
            more discussions and collaboration, and with this in mind, Singapore
            hopes to start a BlueTrace coalition with like-minded national and
            sub-national partners to get the work started. Our vision is for the
            BlueTrace coalition to eventually become a self-sustaining global
            community that can maintain and improve the BlueTrace protocol, and
            provide a global public good. More information on the BlueTrace
            protocol and coalition can be found at{" "}
            <a href="https://bluetrace.io" rel="noopener noreferrer nofollow">
              <u>https://bluetrace.io</u>
            </a>
            .
          </p>
          <p>
            We hope that this document is a useful starting point, and if you
            have feedback or further queries, please submit them via the
            BlueTrace website.
          </p>
          <p style={{ textAlign: "center" }}>
            &middot;&nbsp;&middot;&nbsp;&middot;&nbsp;&middot;&nbsp;&middot;
          </p>
          <p>Team TraceTogether</p>
          <p>9 Apr 2020</p>
        </div>
      </StyledPolicyDocumentContainer>
    </Layout>
  )
}
